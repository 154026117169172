import React from 'react';

const Modal = ({ onClose }) => {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
        <p className="chat-message-modal pageTitle-modal">WaveSwaps Liquidity Nodes</p>
        <p class="pageModal"><span class="modal-content-header">Welcome to WaveSwaps</span><br/>
        Discover the power of recycling illiquid tokens into liquid assets through our Liquidity Nodes and Power Cores. This innovative mechanism allows you to earn daily rewards in WaveCoin while boosting token liquidity across the platform.</p>
        <p class="pageModal"><span class="modal-content-header">New to WaveSwaps?</span><br/>
        We highly recommend checking out our <a href="https://nodes.waveswaps.com/FAQs">FAQ</a> and <a href="https://docs.waveswaps.com" target="_blank" rel="noopener noreferrer">Documentation</a> to understand the process better.</p>
        <p class="pageModal"><span class="modal-content-header">Testing - Training Version</span><br/>
        If you're curious to try before diving in, explore our <a href="https://testnodes.waveswaps.com" target="_blank" rel="noopener noreferrer">Testing - Training Version!</a> —where you can experience all platform features risk-free with <a href="https://faucet.waveswaps.com/index.html" target="_blank" rel="noopener noreferrer">testing tokens.</a> 
        </p>
        <button onClick={onClose}>Go to Platform</button>
      </div>
    </div>
  );
};

export default Modal;
