import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SETTINGS from "./SETTINGS";

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId
const projectId = 'dc491136b814d718978201b6bf2e5b29'

const testnet = {
  chainId: 80002,
  name: 'Amoy',
  currency: 'POL',
  explorerUrl: 'https://amoy.polygonscan.com/',
  rpcUrl: 'https://polygon-amoy-bor-rpc.publicnode.com'
}

const mainnet = {
  chainId: 137,
  name: 'Polygon',
  currency: 'POL',
  explorerUrl: 'https://polygonscan.com',
  rpcUrl: 'https://polygon-mainnet.infura.io/v3/9f1e5354e01044d18b20e08585da8330'
}

// 3. Create a metadata object
const metadata = {
  name: 'WaveCoin',
  description: 'WaveCoin',
  url: 'https://nodes.waveswaps.com',

}

// 5. Create a Web3Modal instance
const web3Modal = createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    enableEmail: true,
    enableEIP6963: true,
  }),
  chains: [mainnet],
  projectId,
  themeMode: 'light',
  themeVariables: {
    '--w3m-accent': '#0b4182'
  },
  enableOnramp: true,

  enableAnalytics: true,
})

console.log('Web3Modal Initialized:', web3Modal);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
