
const SETTINGS = {
    nodeContract:"0x5ca457e16BbD8DA80ee54F362c4Cf6fe5c7f7c0F",
    tokenSaleAddress:"0x093eF8ad83AbC9e22C7c2c19F17b4cb11c837CF2",
    mainContract:"0xd46928C9E6A653A6c1C6Bccfc75eE9De15Ec8Eea",
    usdtAddress:"0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
    tokenAddress:"0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
    globalTokenAddress:"0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
    stakingContract:"0x705a7dcbA4150D47A41ba3cDf6A581C3c3eda70A",
    globalStakingContract:"0x13aD23c7009a54CFa87Fe5566F518eB59F4431Db",
    nativeSymbol: "POL",
    nativeIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    usdtIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    gblSymbol: "GBL",
    tokenSymbol: "WAVE",
    collectionSymbol: "WAVEND",
    openSeaLink: "",
    spatialLink: "",
    collection: "0xB13370BeAcC3B6c426f4aF2895e2Ec417a65C9B2",
    MAX_SUPPLY: 20000,
    AP_PRICE: 1,
    END_TIME: 1843121631 * 1000,
    NEXT_AIRDROP: 1725174000 * 1000,
    NODE_INFO:{
        "1":{
                airdrop_power: 0,
                title: 'WAVE', 
                sort: 'NODE',
                level: 'WAVE NODE', 
                lifespan:'270',
                dailyRewards: 0.5, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node.png", 
                craftElements:[],
                price: 100,
                buyCurrency:"WAVE",
                nodeCurrency:"WAVE",
                hoverText: "WAVE NODE",
                total: 2718
            },
        "2":{
                airdrop_power: 2,
                title: 'CHRONOS', 
                sort: 'NODE',
                level: 'CHRONOS NODE', 
                lifespan:'270',
                dailyRewards: 1, 
                refferalPercent: 1, 
                imgUrl: "https://node.waveswaps.com/node1.png", 
                craftElements:["https://node.waveswaps.com/node.png", "https://node.waveswaps.com/node70.png"],
                price: 110,
                buyCurrency:"",
                nodeCurrency:"WAVE",
                hoverText: "CHRONOS NODE",
                total: 2718
            },
        "3":{
                airdrop_power: 0,
                title: 'STELLAR',
                sort: 'NODE',
                level: 'STELLAR NODE',
                lifespan:'90',
                dailyRewards: 3, 
                refferalPercent: 3,
                imgUrl: "https://node.waveswaps.com/node2.png", 
                craftElements:["https://node.waveswaps.com/node1.png", "https://node.waveswaps.com/node70.png"],
                price: 120,
                buyCurrency:"",
                nodeCurrency:"WAVE",
                hoverText: "STELLAR NODE",
                total: 8154
            },
        "4":{
                airdrop_power: 0,
                title: 'ECLIPSE', 
                sort: 'NODE',
                level: 'ECLIPSE NODE', 
                lifespan:'60',
                dailyRewards: 4.5, 
                refferalPercent: 4.5, 
                imgUrl: "https://node.waveswaps.com/node3.png", 
                craftElements:["https://node.waveswaps.com/node2.png", "https://node.waveswaps.com/node70.png"],
                price: 150,
                buyCurrency:"",
                nodeCurrency:"WAVE",
                hoverText: "ECLIPSE NODE",
                total: 16308
            },
        "5":{
                airdrop_power: 0,
                title: 'QUANTUM', 
                sort: 'NODE',
                level: 'QUANTUM NODE',  
                lifespan:'30',
                dailyRewards: 9, 
                refferalPercent: 6, 
                imgUrl: "https://node.waveswaps.com/node4.png", 
                craftElements:["https://node.waveswaps.com/node3.png", "https://node.waveswaps.com/node70.png"],
                price: 190,
                buyCurrency:"",
                nodeCurrency:"WAVE",
                hoverText: "QUANTUM NODE",
                total: 24462
            },
        "6":{
                airdrop_power: 0,
                title: 'GLOBALIS', 
                sort: 'NODE',
                level: 'GLOBALIS NODE', 
                lifespan:'9',
                dailyRewards: 30, 
                refferalPercent: 9, 
                imgUrl: "https://node.waveswaps.com/node5.png",  
                craftElements:["https://node.waveswaps.com/node4.png", "https://node.waveswaps.com/node70.png"],
                price: 240,
                buyCurrency:"",
                nodeCurrency:"WAVE",
                hoverText: "GLOBALIS NODE",
                total: 81540
            },
        "LOAN":{
                airdrop_power: 0,
                title: 'LOAN NODE', 
                level: 'LOAN NODE', 
                lifespan: '30',
                dailyRewards: 9, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/globalis.webp", 
                craftElements:["https://wave.waveswaps.com/img/quantum.webp", "https://wave.waveswaps.com/img/grosh.webp"],
                price: 270,
                buyCurrency:"WAVE",
                hoverText: "LOAN NODE"
            },
        "BTC":{
                airdrop_power: 0,
                title: 'BTC NODE', 
                level: 'BTC NODE', 
                lifespan:'30',
                dailyRewards: 9, 
                refferalPercent: 9, 
                imgUrl: "https://wave.waveswaps.com/img/btc.webp", 
                craftElements:["https://wave.waveswaps.com/img/quantum.webp", "https://wave.waveswaps.com/img/grosh.webp"],
                price: 0.000001,
                buyLogo: "https://wave.waveswaps.com/img/assets/btc.svg", 
                buyCurrency:"BTC",
                hoverText: "BTC NODE"
            },
        "ETH":{
                airdrop_power: 0,
                title: 'ETH NODE', 
                level: 'ETH NODE', 
                lifespan:'30',
                dailyRewards: 9, 
                refferalPercent: 9, 
                imgUrl: "https://wave.waveswaps.com/img/eth.webp", 
                craftElements:["https://wave.waveswaps.com/img/quantum.webp", "https://wave.waveswaps.com/img/grosh.webp", "https://wave.waveswaps.com/img/pron.webp"],
                price: 0.000123,
                buyLogo: "https://wave.waveswaps.com/img/assets/eth.svg", 
                buyCurrency:"ETH",
                hoverText: "ETH NODE"
            },
        "XRP":{
                airdrop_power: 0,
                title: 'XRP NODE', 
                level: 'XRP NODE', 
                lifespan:'30',
                dailyRewards: 9, 
                refferalPercent: 9, 
                imgUrl: "https://wave.waveswaps.com/img/xrp.webp", 
                craftElements:["https://wave.waveswaps.com/img/quantum.webp", "https://wave.waveswaps.com/img/grosh.webp", "https://wave.waveswaps.com/img/pron.webp"],
                price: 100.0001,
                buyLogo: "https://wave.waveswaps.com/img/assets/xrp.svg", 
                buyCurrency:"XRP",
                hoverText: "XRP NODE"
            },
        "SOL":{
                airdrop_power: 0,
                title: 'SOL NODE', 
                level: 'SOL NODE', 
                lifespan:'30',
                dailyRewards: 9, 
                refferalPercent: 9, 
                imgUrl: "https://wave.waveswaps.com/img/sol.webp", 
                craftElements:["https://wave.waveswaps.com/img/quantum.webp", "https://wave.waveswaps.com/img/grosh.webp", "https://wave.waveswaps.com/img/pron.webp"],
                price: 10.0001,
                buyLogo: "https://wave.waveswaps.com/img/assets/sol.svg", 
                buyCurrency:"SOL",
                hoverText: "SOL NODE"
            },
        "10":{
                airdrop_power: 0,
                title: 'PIPL',
                sort: 'POWER CORE',
                lifespan:'300', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node10.png",
                craftElements:[],
                price: 10,
                buyCurrency:"PIPL",
                hoverText: "PIPL POWER CORE",
                buyToken:"0x8c6D62A61FC70497395E9a25C7699d4dd574E7Cc",
                logoToken:"https://logos.waveswaps.com/assets/PIPL.svg"
            },
        "20":{
                airdrop_power: 0,
                title: 'GETON', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node20.png",  
                craftElements:[],
                price: 0.1,
                buyCurrency:"GETON",
                hoverText: "GETON POWER CORE",
                buyToken:"0x1b9D6a5FC2382A97777FC56CEBb109BCa815C3BE",
                logoToken:"https://logos.waveswaps.com/assets/GETON.svg"
            },
        "30":{
                airdrop_power: 0,
                title: 'GROSH', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node30.png", 
                craftElements:[],
                price: 10,
                buyCurrency:"GROSH",
                hoverText: "GROSH POWER CORE",
                buyToken:"0xAA66E15713A9fE46DAff1E0aB9e29E020bfe4220",
                logoToken:"https://logos.waveswaps.com/assets/GROSH.svg"
            },
        "40":{
                airdrop_power: 0,
                title: 'CODE', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node40.png", 
                craftElements:[],
                price: 10,
                buyCurrency:"CODE",
                hoverText: "CODE POWER CORE",
                buyToken:"0x2d04D3F49D11C11Ad99cacA515d209c741c1bd9B",
                logoToken:"https://logos.waveswaps.com/assets/CODE.svg"
            },
            
        "50":{
                airdrop_power: 0,
                title: 'META', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node50.png", 
                craftElements:[],
                price: 10,
                buyCurrency:"META",
                hoverText: "META POWER CORE",
                buyToken:"0xD51E7065B942D5A5886915C6BEbf42600D546162",
                logoToken:"https://logos.waveswaps.com/assets/META.svg"
            },

        "60":{
                airdrop_power: 0,
                title: 'PRON', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node60.png", 
                craftElements:[],
                price: 0.01,
                buyCurrency:"PRON",
                hoverText: "PRON POWER CORE",
                buyToken:"0x2a6aF8DA13B36F925632a404A3161fE344d8f469",
                logoToken:"https://logos.waveswaps.com/assets/PRON.svg"
            },
        "70":{
                airdrop_power: 0,
                title: 'GBL', 
                lifespan:'300',
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node70.png", 
                craftElements:[],
                price: 1,
                buyCurrency:"GBL",
                hoverText: "GBL POWER CORE",
                buyToken:"0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
                logoToken:"https://logos.waveswaps.com/assets/GBL.svg"
            },
        "80":{
                airdrop_power: 0,
                title: 'WAVE', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://node.waveswaps.com/node80.png", 
                craftElements:[],
                price: 10,
                buyCurrency:"WAVE",
                hoverText: "WAVE POWER CORE",
                buyToken:"0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
                logoToken:"https://logos.waveswaps.com/assets/WAVE.svg"
            },
        "18":{
                airdrop_power: 0,
                title: 'BTC', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/btc.webp", 
                craftElements:[],
                price: 10,
                buyCurrency:"BTC",
                hoverText: "BTC"
            },
        "19":{
                airdrop_power: 0,
                title: 'ETH', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/eth.webp", 
                craftElements:[],
                price: 10,
                buyCurrency:"ETH",
                hoverText: "ETH"
            },
        "14":{
                airdrop_power: 0,
                title: 'XRP', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/xrp.webp", 
                craftElements:[],
                price: 10,
                buyCurrency:"XRP",
                hoverText: "XRP"
            },
        "21":{
                airdrop_power: 0,
                title: 'SOL', 
                dailyRewards: 0, 
                refferalPercent: 0, 
                imgUrl: "https://wave.waveswaps.com/img/sol.webp", 
                craftElements:[],
                price: 10,
                buyCurrency:"SOL",
                hoverText: "SOL"
            },
            
       
    },

    AFFILIATE_INFO: {
        "SAGEMASTER": {
            logo: "https://wave.waveswaps.com/img/affiliate/sagemaster.webp",
            alias: "SAGEMASTER",
            address: "SageMaster",
            telegram: "link",
            description: "Uniswap is a decentralized exchange (DEX) allowing users to swap various cryptocurrencies.",
            referral: "https://uniswap.org/referral_link"
        },
        "UNISWAP": {
            logo: "https://wave.waveswaps.com/img/affiliate/uniswap.svg",
            alias: "Uniswap DEX",
            address: "UniSwap",
            telegram: "link",
            description: "Uniswap is a decentralized exchange (DEX) allowing users to swap various cryptocurrencies.",
            referral: "https://uniswap.org/referral_link"
        },
        "SUSHISWAP": {
            logo: "https://wave.waveswaps.com/img/affiliate/sushiswap.svg",
            alias: "SushiSwap DEX",
            address: "SushiSwap",
            telegram: "link",
            description: "SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi services. SushiSwap is a community-run DEX offering diverse DeFi ",
            referral: "https://sushi.com/referral_link"
        },
        "WAWESWAPS": {
            logo: "https://wave.waveswaps.com/img/affiliate/wshare.svg",
            alias: 'WAVESWAPS FEE',
            address: 'WaveSwaps',
            telegram: "link",
            description: (
              <span>
                Lorem ipsum, lorem ipsum Learn more about Uniswap’s functionality by visiting the{' '}
                <a href="https://docs.uniswap.org/" target="_blank" rel="noopener noreferrer">
                  official documentation
                </a>.
              </span>
            ),
            referral: 'https://uniswap.org/referral'
          }
        // Add more affilaites as needed...
    },

    ASSETS: {
        WaveShare: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/WSHARE.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e",
        name: "WaveShare",
        symbol: "WSHARE",
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0x51C2b96740aa4591E84747522559B870e3F531C3',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        totalSupply: 100,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x51c2b96740aa4591e84747522559b870e3f531c3/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
        WaveCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/WAVE.svg",
        chainExplorer: "https://polygonscan.com/token/0x3c2d4cfF75434205Ec98980f7B506d035CA7d923",
        name: "WaveCoin",
        symbol: "WAVE",
        walletAddress: '0x13bcf1f7737cc85a70632f5bfa75a0f1cc56d400',
        wallet: '0xcFbA2075F6F4bd815aef59b8554e17a20da67698',
        tokenAddress1: '0x3c2d4cfF75434205Ec98980f7B506d035CA7d923',
        tokenAddress2: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 1000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0x13bcf1f7737cc85a70632f5bfa75a0f1cc56d400",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE',
      },
      GlobalToken: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/GBL.svg",
        chainExplorer: "https://polygonscan.com/token/0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6",
        name: "GlobalToken",
        symbol: "GBL",
        walletAddress: '0xe0b2b83718400188528f914b9991ff3246fa009b',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0xC14A7747cFec02CfeA62E72BB93538DE6B2078E6',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 17900000,
        chart: "https://coinmarketcap.com/currencies/global-token/",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'XXE1HV4MS8KMJ69PN38JRIGFUMT8DKBWRY',
      },
      MetaCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/META.svg",
        chainExplorer: "https://polygonscan.com/token/0xD51E7065B942D5A5886915C6BEbf42600D546162",
        name: "MetaCoin",
        symbol: "META",
        walletAddress: '0xc4d61aee6bcc376a451f6f4b0359fe0755ce8128',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0xd51e7065b942d5a5886915c6bebf42600d546162',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 210000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0xc4d61aee6bcc376a451f6f4b0359fe0755ce8128/",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'GYZEQUPJMXVFVX4T2WVR7BQ4AIRZGSJK2T',
      },
      CryptoCode: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/CODE.svg",
        chainExplorer: "https://polygonscan.com/token/0x2d04d3f49d11c11ad99caca515d209c741c1bd9b",
        name: "CryptoCode",
        symbol: "CODE",
        walletAddress: '0xade8045983eed33d604dbaa56e32a22b63ca9b12',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0x2d04d3f49d11c11ad99caca515d209c741c1bd9b',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 21000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0xade8045983eed33d604dbaa56e32a22b63ca9b12/",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'IKQ8JR39YAI2ICYBAFD2IUB2AKHR79Y2TR',
      },
      PiplCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/PIPL.svg",
        chainExplorer: "https://polygonscan.com/token/0x8c6d62a61fc70497395e9a25c7699d4dd574e7cc",
        name: "PiplCoin",
        symbol: "PIPL",
        walletAddress: '0xc7d018cf9f5fd54d466321cf9398420c3bd26fca',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0x8c6d62a61fc70497395e9a25c7699d4dd574e7cc',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 261797900,
        chart: "https://coinmarketcap.com/dexscan/polygon/0xc7d018cf9f5fd54d466321cf9398420c3bd26fca/",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'G43J4K2HTUD7DZTMVUNWPNS19H4BBJWCEN',
      },
      GroshCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/GROSH.svg",
        chainExplorer: "https://polygonscan.com/token/0xaa66e15713a9fe46daff1e0ab9e29e020bfe4220",
        name: "GroshCoin",
        symbol: "GROSH",
        walletAddress: '0xcc4ee9d12b8985f353bcd7b15f17c36c05cd2a0b',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0xaa66e15713a9fe46daff1e0ab9e29e020bfe4220',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 210000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0xcc4ee9d12b8985f353bcd7b15f17c36c05cd2a0b/",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'EVXHEG39R4CAW1PSSNB5SS4V9XCSY791V7',
      },
      GetonCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/GETON.svg",
        chainExplorer: "https://polygonscan.com/token/0x1b9d6a5fc2382a97777fc56cebb109bca815c3be",
        name: "GetonCoin",
        symbol: "GETON",
        walletAddress: '0x91c6a6267b44cc0d5312c3dfe67d7b9d339b73e9',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0x1b9d6a5fc2382a97777fc56cebb109bca815c3be',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 1542000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0x91c6a6267b44cc0d5312c3dfe67d7b9d339b73e9/",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: '55K63YUSMCUSKPJ2RXGFJPBEGCNNXD91CA',
      },
      PronCoin: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/PRON.svg",
        chainExplorer: "https://polygonscan.com/token/0x2a6af8da13b36f925632a404a3161fe344d8f469",
        name: "PronCoin",
        symbol: "PRON",
        walletAddress: '0x10daccc00e75d7f3456a49acfbb3ab81fd13ae24',
        wallet: '0x5da7e20f2bAa56e8DB767F53cB614CBB208fB067',
        tokenAddress1: '0x2a6af8da13b36f925632a404a3161fe344d8f469',
        tokenAddress2: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 6,   // Decimals for tokenAddress2
        totalSupply: 69000000,
        chart: "https://coinmarketcap.com/dexscan/polygon/0x10daccc00e75d7f3456a49acfbb3ab81fd13ae24/",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'QWK9DBIUHMV6X2WMGCVBY2I6T1NWV5CWUN',
      },
      
      // Add other assets similarly...
    },

    CRAFT_DICT:{
        "1_10_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_20_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_30_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_40_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_50_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_60_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_70_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],
        "1_80_0_0": ["https://wave.waveswaps.com/img/chronos.webp", 2],

        "2_10_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_20_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_30_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_40_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_50_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_60_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_70_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],
        "2_80_0_0": ["https://wave.waveswaps.com/img/stellar.webp", 3],

        "3_10_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_30_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_40_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_40_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_50_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_60_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_70_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],
        "3_80_0_0": ["https://wave.waveswaps.com/img/eclipse.webp", 4],

        "4_10_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_40_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_50_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_50_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_50_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_60_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_70_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],
        "4_80_0_0": ["https://wave.waveswaps.com/img/quantum.webp", 5],

        "5_10_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_50_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_60_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_60_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_60_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_60_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_70_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],
        "5_80_0_0": ["https://wave.waveswaps.com/img/globalis.webp", 6],

      }


};
export default SETTINGS;
