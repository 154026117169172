// src/components/Nodes.js
import React, { useState } from 'react';
import { Button, Spinner, Card, ProgressBar } from 'react-bootstrap';
import { BrowserProvider, Contract } from 'ethers'
import NodesABI from '../abis/NodesABI';
import SETTINGS from '../SETTINGS';
import governanceImg from '../governance.png';
import logoToken from '../logoToken.svg';

const CardNode = ({ nft, provider, getBalanceFunction, amount = 0 }) => {
  const [loading, setLoading] = useState(false);
  const [buyAvailable, setBuyAvailable] = useState(false);
  const [txMessage, setTxMessage] = useState('');
  const getProgress = (timestamp) => {
    const now = Math.floor(Date.now() / 1000);
    const timePassed = now - timestamp;
    const progress = (timePassed / 86400) * 100;
    return Math.min(progress, 100);
  };

  const claim = async () => {
    try {
      setLoading(true);
      setTxMessage("Claiming...");
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const nftContract = new Contract(
        SETTINGS.nodeContract,
        NodesABI,
        signer
      );
      const claimTx = await nftContract.claimRewards(nft.tokenId);
      await claimTx.wait();
      nft.unclaimedRewards = 0;
      nft.lastClaim = Math.floor(Date.now());
    } catch (error) {
      console.error("Error claiming:", error);
      setTxMessage("Failed to Claim. Please try again.");
    } finally {
      await getBalanceFunction();
      setLoading(false);
      // Optionally reset the transaction message after a delay
      setTimeout(() => setTxMessage(""), 5000);
    }

  };
  if (loading) {
    return (
      <div className=" text-center">
        <br />
        <br />
        <Spinner animation="border" role="status" className='loaderBig' />
        <p className='loaderMsg'>{txMessage}</p>
      </div>
    );
  }

  return (
    <Card className='card-fix text-center chat-message h-100 qwrlds-card'>
      <Card.Body className='d-flex fled-flex flex-column card-padding'>
        <Card.Title className='h6 mt-md-0 mt-3 mb-md-2 mb-0'>
          {nft.name}
        </Card.Title>
        <Card.Img variant="top" src={nft.imageURL} alt={`QWRLD ${nft.tokenId}`} />
        <div className='mt-md-3 mt-0'>
          {nft.tokenType <= 6 ? (<><span className='tokenCardId'><small>ID:</small>{nft.tokenId}</span>
            <h6 className='fw-normal mb-3 pt-2'>
              <small>Daily:</small> <img src={logoToken} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
              <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(nft.dailyReward)} {SETTINGS.tokenSymbol}</b>
            </h6>
            {nft.dailyReward > 0 &&(<>
            {nft.unclaimedRewards > 0 ?
              (<Button variant="primary" onClick={claim} className="buton buton-secondary buton-sec-small">
                CLAIM {nft.unclaimedRewards} {SETTINGS.tokenSymbol}
              </Button>) : (
                <div>
                  <Button variant="primary" disabled className="buton buton-disabled buton-secondary buton-sec-small">
                    <b>CLAIM {nft.dailyReward} {SETTINGS.tokenSymbol}</b>
                  </Button>
                  <ProgressBar
                    style={{ height: "5px", marginTop: "5px" }}
                    animated
                    striped
                    now={getProgress(nft.lastClaim)}
                  />
                </div>
              )}
              </>)}
            <br />
            <p className='fw-normal mt-2 pt-1 small mb-0'>
              <img src={governanceImg} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
              <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(nft.rawAirdropPower)} AP</b>
            </p>
          </>) : (
            <>
              <h6 className='pt-2 mb-3 fw-normal'>
                <small>Amount:</small><b> {amount}x</b>
              </h6>
              <br />
              <h6 className='fw-normal mt-2 pt-1 mb-0'>
                <img src={governanceImg} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
                <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(nft.rawAirdropPower * amount)} AP</b>
              </h6></>
          )}
        </div>
      </Card.Body>
    </Card>
  );

};

export default CardNode;
